<template>
  <div :class="$style.container">
    <vue-good-table
      :class="$style.table"
      :columns="columns"
      :rows="dealers"
      styleClass="vgt-table striped"
      fixed-header
      @on-sort-change="onSortChange"
    >
    </vue-good-table>
    <Pagination
      v-if="pageCount > 1"
      @go-next="goNext()"
      @go-prev="goPrev()"
      :curPage="page"
      :lastPage="pageCount"
    />
  </div>
</template>

<script>
import delivery from '@/delivery'
import Pagination from '@/components/atoms/Paginations.vue'

export default {
  components: { Pagination },
  mounted() {
    this.getListWithBonusCount()
  },
  computed: {
    pageCount() {
      return Math.ceil(this.total / this.limit)
    },
  },
  data() {
    return {
      columns: [
        {
          label: 'Название компании',
          field: 'name',
          type: 'string',
        },
        {
          label: 'Email',
          field: 'email',
          type: 'string',
        },
        {
          label: 'Текущее кол-во бонусных баллов',
          field: 'totalTransactions',
          type: 'number',
        },
        {
          label: 'Начисленные бонусные баллы',
          field: 'accruedTransactions',
          type: 'number',
        },
        {
          label: 'Потраченные бонусные баллы',
          field: 'spentTransactions',
          type: 'number',
        },
      ],
      limit: 20,
      page: 1,
      total: 0,
      dealers: [],
      isShow: false,
      orderBy: null,
      orderDir: null,
    }
  },
  methods: {
    async goNext() {
      if (this.page < this.total) {
        this.page += 1
        await this.getListWithBonusCount()
      }
    },
    async goPrev() {
      if (this.page > 1) {
        this.page -= 1
        await this.getListWithBonusCount()
      }
    },
    async onSortChange(params) {
      let orderBy = null
      let orderDir = null
      if (params[0].type !== 'none') {
        orderBy = params[0].field
        orderDir = params[0].type
      }
      this.page = 1
      this.orderBy = orderBy
      this.orderDir = orderDir
      await this.getListWithBonusCount()
    },
    async getListWithBonusCount() {
      const { value, error } =
        await delivery.AddwineCore.DealersActions.getListWithBonusCount({
          page: this.page,
          limit: this.limit,
          orderBy: this.orderBy,
          orderDir: this.orderDir,
        })

      if (error) return

      this.isShow = false
      this.dealers = value.data
      this.total = value.meta.count
    },
  },
}
</script>

<style lang="scss" module>
.container {
  padding: 1rem;
}
</style>
